import { CurrentUserService } from 'app/services/api/auth/current-user.service';
import { Component, OnInit } from '@angular/core';
import { StorageService } from '../../services/storage.service';


@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  password = '';
  confermaPassword = '';
  error = '';
  constructor(private cuService: CurrentUserService, private storage: StorageService) { }

  ngOnInit(): void {
  }

  cambiaPassword() {
    this.error = '';
    if (this.password == '' || this.confermaPassword == '') {
      this.error = 'Attenzione: entrambi i campi sono obbligatori';
    } else if (this.password != this.confermaPassword) {
      this.error = 'Attenzione: i campi password e conferma password devono coincidere';
    } else {
      this.cuService.updatePassword({password: this.password}, () => {
        const user = this.storage.get('cpmauth');
        user.dati_utente.temporaryPassword = 0;
        this.storage.deleteCookie('cpmauth');
        this.storage.set('cpmauth', user);
        document.location.href = '/';

      });
    }
  }
}
