import { INavData } from '@coreui/angular';
import { StorageService } from './services/storage.service'
const sService = new StorageService();
const cpmauth = sService.get("cpmauth");
console.log(cpmauth);
console.log(cpmauth != null && cpmauth.role=="admin");

let items =[];
if(cpmauth != null && cpmauth.role=="area-manager"){
  items = [
    {
      name: 'Dashboard',
      url: '/dashboard',
      icon: 'cil-speedometer'
     
    },
    {
      name: 'Segnalazioni',
      url: '/admin/report',
      icon: 'cil-phone'
     
    }
  ]
}  else if(cpmauth != null && (cpmauth.role=="customer")){
  items = [
    {
      name: 'Dashboard',
      url: '/dashboard',
      icon: 'cil-speedometer'
     
    },
    {
      name: 'Segnalazioni',
      url: '/admin/report',
      icon: 'cil-phone'
     
    }
  ]
}else if(cpmauth != null && (cpmauth.role=="tech" || cpmauth.role=="tech-ext")){
  items = [
    {
      name: 'Dashboard',
      url: '/dashboard',
      icon: 'cil-speedometer'
     
    },
    {
      name: 'Segnalazioni',
      url: '/admin/report',
      icon: 'cil-phone'
     
    }
  ]
}
else if(cpmauth != null && cpmauth.role=="admin"){
  items = [
    {
      name: 'Dashboard',
      url: '/dashboard',
      icon: 'cil-speedometer'
     
    },
    {
      name: 'Gestione utenti',
      url: '/admin/gestioneutenti',
      icon: 'cil-group'
     
    },
    {
      name: 'Clienti',
      url: '/admin/customers',
      icon: 'cil-voice-over-record'
     
    }
    ,
    
    {
      name: 'Edifici',
      url: '/admin/building',
      icon: 'cil-factory'
     
    },
    {
      name: 'Impianti',
      url: '/admin/assets',
      icon: 'cil-lightbulb'
     
    },
    {
      name: 'Energy management',
      url: '/admin/em',
      icon: 'cil-bolt'
     
    },
    {
      name: 'Chiamate',
      url: '/admin/report',
      icon: 'cil-phone'
     
    },
    {
      name: 'Manutenzione',
      url: '/admin/scheduled-maintenance',
      icon: 'icon-wrench'
     
    },
    {
      name: 'Configurazioni',
      icon: 'cil-cog',
      url: '/admin/config',
      children: [{
        name: 'Tipi di impianto',
        url: '/admin/config/asset-types',
        icon: 'noicon'
      },{
        name: 'Tipi di lavorazione',
        url: '/admin/config/operation-types',
        icon: 'noicon'
      },{
        name: 'Componenti',
        url: '/admin/device_type',
        icon: 'noicon'
      },
      
      {
        name: 'Zone',
        url: '/admin/zone',
        icon: 'noicon'
       
      },]
    },
    {
      name: 'Attività',
      url: '/admin/attivita2',
      icon: 'icon-wrench'
     
    },
    {
      name: 'Statistiche',
      url: '/admin/statistiche',
      icon: 'icon-wrench'
     
    },
    {
      name: 'Import',
      url: '/admin/import',
      icon: 'icon-wrench'
     
    }

    

  ];
}
export const navItems: INavData[] = items;
/*

export const navItems: INavData[] = [
  {
    name: 'Dashboard',
    url: '/dashboard',
    icon: 'icon-speedometer',
    badge: {
      variant: 'info',
      text: 'NEW'
    }
  },
  {
    title: true,
    name: 'Theme'
  },
  {
    name: 'Colors',
    url: '/theme/colors',
    icon: 'icon-drop'
  },
  {
    name: 'Typography',
    url: '/theme/typography',
    icon: 'icon-pencil'
  },
  {
    title: true,
    name: 'Components'
  },
  {
    name: 'Base',
    url: '/base',
    icon: 'icon-puzzle',
    children: [
      {
        name: 'Cards',
        url: '/base/cards',
        icon: 'icon-puzzle'
      },
      {
        name: 'Carousels',
        url: '/base/carousels',
        icon: 'icon-puzzle'
      },
      {
        name: 'Collapses',
        url: '/base/collapses',
        icon: 'icon-puzzle'
      },
      {
        name: 'Forms',
        url: '/base/forms',
        icon: 'icon-puzzle'
      },
      {
        name: 'Navbars',
        url: '/base/navbars',
        icon: 'icon-puzzle'

      },
      {
        name: 'Pagination',
        url: '/base/paginations',
        icon: 'icon-puzzle'
      },
      {
        name: 'Popovers',
        url: '/base/popovers',
        icon: 'icon-puzzle'
      },
      {
        name: 'Progress',
        url: '/base/progress',
        icon: 'icon-puzzle'
      },
      {
        name: 'Switches',
        url: '/base/switches',
        icon: 'icon-puzzle'
      },
      {
        name: 'Tables',
        url: '/base/tables',
        icon: 'icon-puzzle'
      },
      {
        name: 'Tabs',
        url: '/base/tabs',
        icon: 'icon-puzzle'
      },
      {
        name: 'Tooltips',
        url: '/base/tooltips',
        icon: 'icon-puzzle'
      }
    ]
  },
  {
    name: 'Buttons',
    url: '/buttons',
    icon: 'icon-cursor',
    children: [
      {
        name: 'Buttons',
        url: '/buttons/buttons',
        icon: 'icon-cursor'
      },
      {
        name: 'Dropdowns',
        url: '/buttons/dropdowns',
        icon: 'icon-cursor'
      },
      {
        name: 'Brand Buttons',
        url: '/buttons/brand-buttons',
        icon: 'icon-cursor'
      }
    ]
  },
  {
    name: 'Charts',
    url: '/charts',
    icon: 'icon-pie-chart'
  },
  {
    name: 'Icons',
    url: '/icons',
    icon: 'icon-star',
    children: [
      {
        name: 'CoreUI Icons',
        url: '/icons/coreui-icons',
        icon: 'icon-star',
        badge: {
          variant: 'success',
          text: 'NEW'
        }
      },
      {
        name: 'Flags',
        url: '/icons/flags',
        icon: 'icon-star'
      },
      {
        name: 'Font Awesome',
        url: '/icons/font-awesome',
        icon: 'icon-star',
        badge: {
          variant: 'secondary',
          text: '4.7'
        }
      },
      {
        name: 'Simple Line Icons',
        url: '/icons/simple-line-icons',
        icon: 'icon-star'
      }
    ]
  },
  {
    name: 'Notifications',
    url: '/notifications',
    icon: 'icon-bell',
    children: [
      {
        name: 'Alerts',
        url: '/notifications/alerts',
        icon: 'icon-bell'
      },
      {
        name: 'Badges',
        url: '/notifications/badges',
        icon: 'icon-bell'
      },
      {
        name: 'Modals',
        url: '/notifications/modals',
        icon: 'icon-bell'
      }
    ]
  },
  {
    name: 'Widgets',
    url: '/widgets',
    icon: 'icon-calculator',
    badge: {
      variant: 'info',
      text: 'NEW'
    }
  },
  {
    divider: true
  },
  {
    title: true,
    name: 'Extras',
  },
  {
    name: 'Pages',
    url: '/pages',
    icon: 'icon-star',
    children: [
      {
        name: 'Login',
        url: '/login',
        icon: 'icon-star'
      },
      {
        name: 'Register',
        url: '/register',
        icon: 'icon-star'
      },
      {
        name: 'Error 404',
        url: '/404',
        icon: 'icon-star'
      },
      {
        name: 'Error 500',
        url: '/500',
        icon: 'icon-star'
      }
    ]
  },
  {
    name: 'Disabled',
    url: '/dashboard',
    icon: 'icon-ban',
    badge: {
      variant: 'secondary',
      text: 'NEW'
    },
    attributes: { disabled: true },
  },
  {
    name: 'Download CoreUI',
    url: 'http://coreui.io/angular/',
    icon: 'icon-cloud-download',
    class: 'mt-auto',
    variant: 'success',
    attributes: { target: '_blank', rel: 'noopener' }
  },
  {
    name: 'Try CoreUI PRO',
    url: 'http://coreui.io/pro/angular/',
    icon: 'icon-layers',
    variant: 'danger',
    attributes: { target: '_blank', rel: 'noopener' }
  }
];


*/