import { Injectable } from '@angular/core';
import { AuthService } from '../auth.service'
@Injectable({
  providedIn: 'root'
})
export class CurrentUserService extends AuthService {

  getUser(callback: (user: any) => void) {
    return this.processGet(`user/current`, callback);
  }

  logout(callback: (user: any) => void) {
    return callback(null);
    return this.processGet(`auth/logout`, callback);
  }


  update(data,callback: (user: any) => void) {
    return this.processPut(`current`,data, callback);
  }

  updatePassword(data,callback: (user: any) => void) {
    return this.processPut(`user/current/password`,data, callback);
  }
}
