import {Component, ViewChild} from '@angular/core';
import {  NavigationEnd, Router } from '@angular/router';
import { navItems } from '../../_nav';
import { StorageService } from "app/services/storage.service";
import { CurrentUserService } from "app/services/api/auth/current-user.service";
import { ToastComponent } from '../../helpers/toast/toast.component';
@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html',
  styleUrls: ['./default-layout.component.scss']
})

export class DefaultLayoutComponent {
  dati_utente:any;
  @ViewChild('toast') public toast: ToastComponent;
  constructor(protected storage:StorageService, protected cUser:CurrentUserService,private router: Router) {
    this.dati_utente = storage.get("cpmauth").dati_utente;
    
 
    this.router.events.subscribe((event)=>{
      if (event instanceof NavigationEnd) {
        if(this.router.getCurrentNavigation().extras.state){
          let state = this.router.getCurrentNavigation().extras.state;
          
          this.toast.showToast(state.message,state.title?state.title:"",state.color?state.color:"success");
        }
      
      }
    })
  }
  public sidebarMinimized = false;
  public navItems = navItems;

  toggleMinimize(e) {
    this.sidebarMinimized = e;
  }

  logout(){
    this.cUser.logout(()=>{
      this.storage.deleteCookie("cpmauth");
      this.router.navigate(['/login'])
    })
    
  }
}
