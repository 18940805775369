// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  apiUlr: "http://141.95.174.224/stampe",
  baseUrl: "https://stage.cpm.salero.ovh/be/v1"
  //baseUrl:"https://cpm.salero.ovh/be/v1"
  //baseUrl:"http://localhost:8100/v1"
};
