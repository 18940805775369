import { Component, OnInit } from '@angular/core';
import { CurrentUserService } from "app/services/api/auth/current-user.service";
import { StorageService } from "app/services/storage.service";
@Component({
  selector: 'app-profilo',
  templateUrl: './profilo.component.html',
  styleUrls: ['./profilo.component.scss']
})
export class ProfiloComponent implements OnInit {
  dati_utente:any;
  loaded=false;
  errors:any;
  constructor(protected cUser:CurrentUserService, private storage:StorageService) { }

  ngOnInit(): void {
    this.loaded = false;
    this.cUser.getUser((dati_utente)=>{
      this.loaded = true;
      this.dati_utente = dati_utente;
      this.dati_utente.confirmPassword = "";
      this.dati_utente.password = "";
    })
  }

  salva(){
    const validate = this.validate();
    console.log("validate",validate)
    if(!validate.success){
      this.errors = validate.errors;
    }else{
      let data = {
        name: this.dati_utente.name,
        lastName: this.dati_utente.lastName,
      }
      this.cUser.update(data,(res)=>{
        if(this.dati_utente.password == ""){
          this.cUser.getUser((dati_utente)=>{
            this.dati_utente = dati_utente;
            this.dati_utente.confirmPassword = "";
            this.dati_utente.password = "";
            const user = this.storage.get("cpmauth");
            user.dati_utente = dati_utente;
            this.storage.deleteCookie("cpmauth");
            this.storage.set("cpmauth", user);
            
            location.href="/";

          });
        }else{
          let pwdata = {
            password: this.dati_utente.password,
          }
          this.cUser.updatePassword(pwdata,(res)=>{
            this.cUser.getUser((dati_utente)=>{
              this.dati_utente = dati_utente;
              this.dati_utente.confirmPassword = "";
              this.dati_utente.password = "";
              const user = this.storage.get("cpmauth");
              user.dati_utente = dati_utente;
              this.storage.deleteCookie("cpmauth");
              this.storage.set("cpmauth", user);
              location.href="/";
  
            });
          });
        }
      })
      
    /*  this.utente.save(()=>{
        this.router.navigate(['/admin/gestioneutenti'])
      });
    }*/
    }
  }

  
  validate(){
    
    let ret = {
      success: true,
      errors:<{
        email: string,
        name: string,
        lastname: string,
        password: string,
        confirmPassword: string,
      }>{}
    }
    if(this.dati_utente.email == ""){
      ret.success = false;
      ret.errors.email = "Il campo email è obbligatorio";
    }else if(!this.validateEmail(this.dati_utente.email)){
      ret.success = false;
      ret.errors.email = "Il formato del valore inserito non è valido, deve essere un indirizzo email";
    }

    if(this.dati_utente.name == ""){
      ret.success = false;
      ret.errors.name = "Il campo nome è obbligatorio";
    }
    if(this.dati_utente.lastname == ""){
      ret.success = false;
      ret.errors.lastname = "Il campo cognome è obbligatorio";
    }
    console.log(this.dati_utente.password);
    console.log(this.dati_utente.confirmPassword);
    if(this.dati_utente.password != this.dati_utente.confirmPassword){
      ret.success = false;
      ret.errors.password = "I campi password e conferma password devono coincidere";
      ret.errors.confirmPassword = "I campi password e conferma password devono coincidere";
    }

    return ret;
    
    }


    validateEmail(mail)  {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail))
      {
        return (true)
      }
        
        return (false)
    }
  

}
