import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Import Containers
import { DefaultLayoutComponent } from './containers';

import { P404Component } from './views/error/404.component';

import { P500Component } from './views/error/500.component';
import { LoginComponent } from './views/login/login.component';
import { ProfiloComponent } from './views/profilo/profilo.component';
import { RegisterComponent } from './views/register/register.component';
import { StorageService } from './services/storage.service';
import { ChangePasswordComponent } from './views/change-password/change-password.component';

let sService = new StorageService();
const cpmauth = sService.get("cpmauth");
console.log('cpmauth: @15', cpmauth);
let routes = null;
if(cpmauth == false){
  routes = [
    {
      path: '',
      redirectTo: 'login',
      pathMatch: 'full',
    },
    {
      path: 'login',
      component: LoginComponent,
      data: {
        title: 'Login Page'
      }
    },
    { path: '**', component: LoginComponent }
  ]
} else if(cpmauth.dati_utente.temporaryPassword == 1) {
  routes = [
    {
      path: '',
      redirectTo: 'change-temporary-password',
      pathMatch: 'full',
    },
    {
      path: 'change-temporary-password',
      component: ChangePasswordComponent,
      data: {
        title: 'Login Page'
      }
    },
    { path: '**', component: ChangePasswordComponent }
  ];
}else{

  routes = [
    {
      path: '',
      redirectTo: 'dashboard',
      pathMatch: 'full',
    },
    
    {
      path: '404',
      component: P404Component,
      data: {
        title: 'Page 404'
      }
    },
    {
      path: '500',
      component: P500Component,
      data: {
        title: 'Page 500'
      }
    },
    {
      path: 'login',
      component: LoginComponent,
      data: {
        title: 'Login Page'
      }
    },
    {
      path: 'register',
      component: RegisterComponent,
      data: {
        title: 'Register Page'
      }
    },
    {
      path: '',
      component: DefaultLayoutComponent,
      data: {
        title: 'Home'
      },
      children: [
        {
          path: 'profilo',
          component: ProfiloComponent,
          data: {
            title: 'Profilo utente'
          }
        },
        {
          path: 'admin',
          
          loadChildren: () => import('./views/admin/admin.module').then(m => m.AdminModule)
        },
        {
          path: 'base',
          loadChildren: () => import('./views/base/base.module').then(m => m.BaseModule)
        },
        {
          path: 'buttons',
          loadChildren: () => import('./views/buttons/buttons.module').then(m => m.ButtonsModule)
        },
        {
          path: 'charts',
          loadChildren: () => import('./views/chartjs/chartjs.module').then(m => m.ChartJSModule)
        },
        {
          path: 'dashboard',
          loadChildren: () => import('./views/dashboard/dashboard.module').then(m => m.DashboardModule)
        },
        {
          path: 'icons',
          loadChildren: () => import('./views/icons/icons.module').then(m => m.IconsModule)
        },
        {
          path: 'notifications',
          loadChildren: () => import('./views/notifications/notifications.module').then(m => m.NotificationsModule)
        },
        {
          path: 'theme',
          loadChildren: () => import('./views/theme/theme.module').then(m => m.ThemeModule)
        },
        {
          path: 'widgets',
          loadChildren: () => import('./views/widgets/widgets.module').then(m => m.WidgetsModule)
        }
      ]
    },
    { path: '**', component: P404Component }
  ];

}



@NgModule({
  imports: [ RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy', useHash: false }) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule {}
