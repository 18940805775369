import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss']
})
export class ToastComponent implements OnInit {

  show=false;
  text="";
  head=""
  color="success"
  constructor() { }

  ngOnInit(): void {
  }

  showToast(text,head="",color='success',timeout=5){
    this.text=text;
    this.head=head;
    this.color=color;
    this.show=true;
    if(timeout){
      const that = this;
      setTimeout(()=>{that.show = false},timeout*1000)
    }
  }
}
