import { Component } from '@angular/core';
import { StorageService } from "app/services/storage.service";
import { LoginService } from "app/services/api/login.service";
import { CurrentUserService } from "app/services/api/auth/current-user.service";
@Component({
  selector: 'app-dashboard',
  templateUrl: 'login.component.html'
})
export class LoginComponent { 
  username:string = "";
  error:string = "";
  password:string = "";
  constructor(private storage:StorageService, private apilogin: LoginService, private cUser: CurrentUserService ){

  }
  

  login(){
    this.apilogin.login(this.username, this.password, (success,user)=>{
      if(!success){
        this.error = "Attenzione: username o password errati";
      }else{
        this.storage.set("cpmauth", user);
        this.cUser.getUser((dati_utente)=>{
          user.dati_utente = dati_utente;

          this.storage.deleteCookie("cpmauth");
          this.storage.set("cpmauth", user);
          document.location.href = '/';
        })
       
      }

    }) 

  }
}
